import { CSSObject, BoxProps } from '@chakra-ui/react';

export const FooterMoreOpenText: CSSObject = {
  color: 'alpha.400',
};

export const BoxMoreText: CSSObject = {
  w: '8',
  cursor: 'default',
};

export const DeductionsText: CSSObject = {
  color: 'gray.400',
  fontSize: '2xs',
  fontWeight: 'semibold',
};

export const FooterBodyText: CSSObject = {
  color: 'gray.300',
};

export const footerWrapperExpanded: BoxProps = {
  sx: {
    pt: '0',
  },
};

export const toteMultiGrid: CSSObject = {
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '6',
  display: 'grid',
};

export const toteMultiGridText: CSSObject = {
  fontSize: 'xs',
  w: 'full',
  color: 'white',
  mb: '2',
  '&.raceNumber': {
    borderBottom: '1px dashed',
    borderColor: '#00000040',
    boxShadow: '0px 1px 0px 0px #FFFFFF1A',
  },
};