import { CSSObject } from '@chakra-ui/react';
import { BottomRowSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/BottomRow/styles/BottomRow.styles';

export const bottomRowStyles: BottomRowSchema = {
  flexWrapper: {
    w: 'full',
  },
  buttonCashOutDesc: {
    w: 'full',
    fontFamily: 'accent',
    bgGradient:
      'linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-600) 100%)',
    boxShadow:
      '0px 4px 6px 0px var(--bc-colors-blackAlpha-500), 0px 0px 0px 2px #ffda2f inset, 0px 0px 0px 4px #20273f inset, 0px 0px 0px 5px var(--bc-colors-whiteAlpha-50) inset',
    p: '3',
    sx: {
      '.cashoutValue': {
        color: 'beta.500',
      },
      '&:active, &:hover': {
        '.cashoutValue': {
          color: 'beta.500',
        },
      },
      _hover: {
        color: 'beta.500',
        bgGradient:
          'linear-gradient(180deg, var(--bc-colors-alpha-800) 0%, var(--bc-colors-alpha-800) 100%)',
        boxShadow:
          '0px 0px 0px 2px var(--bc-colors-beta-400) inset,0px 0px 0px 3.5px var(--bc-colors-alpha-700) inset',
      },
    },
  },
  buttonCashOutAmount: {
    variant: 'primary',
    height: '29px',
    fontFamily: 'heading',
    fontWeight: 'normal',
    _hover: {
      bgGradient: 'linear(180deg, beta.400 0%, beta.500 100%)',
      textShadow: '0px 0px 8px #fbc91580',
      color: 'beta.900',
    },
  },
  buttonGroupWrapper: {
    w: 'full',
  },
  iconCashOut: {
    fill: 'beta.500',
    boxSize: '7',
  },
  cashoutText: {
    color: 'beta.500',
    pt: '0',
    fontSize: 'sm',
  },
  cashoutValue: {
    lineHeight: 'normal',
    pt: '0',
    fontSize: 'sm',
  },
};
export const LinkVenueName: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  ml: '5',
};
export const RaceTime: CSSObject = {
  color: 'white',
  fontWeight: 'medium',
  alignSelf: 'flex-end',
  ml: '5',
};
export const BonusBetCheckMark: CSSObject = {
  '&&': {
    display: 'none',
  },
};
export const BonusBetText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  fontFamily: 'accent',
  bg: 'alpha.900',
  borderRadius: 'md',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.25)',
  px: '2',
  py: '1',
  lineHeight: 'normal',
};
export const BetReturn: CSSObject = {
  bg: 'beta.500',
  color: 'beta.800',
  fontWeight: 'extrabold',
  borderRadius: 'base',
  fontSize: '2xs',
  boxShadow: '0px 2px 5px 0px var(--bc-colors-blackAlpha-400)',
  px: '1',
};
