import * as goldenrush from './RaceTypeAvailable.styles.goldenrush';
import * as juicybet from './RaceTypeAvailable.styles.juicybet';
import * as vikingbet from './RaceTypeAvailable.styles.vikingbet';
import * as sterlingparker from './RaceTypeAvailable.styles.sterlingparker';
import * as slambet from './RaceTypeAvailable.styles.slambet';
import * as gigabet from './RaceTypeAvailable.styles.gigabet';
import * as goldenbet888 from './RaceTypeAvailable.styles.goldenbet888';
import * as betgalaxy from './RaceTypeAvailable.styles.betgalaxy';
import * as oldgill from './RaceTypeAvailable.styles.oldgill';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  goldenrush,
  juicybet,
  vikingbet,
  sterlingparker,
  slambet,
  gigabet,
  goldenbet888,
  betgalaxy,
  oldgill,
};
