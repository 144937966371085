import { CSSObject } from '@chakra-ui/react';
import { RaceTypeAvailableSchema } from './RaceTypeAvailable.styles';

export const FlexRaceAvailableWrapper: CSSObject = {
  color: 'white',
};

export const raceTypeAvailableStyles: RaceTypeAvailableSchema = {
  raceTypeButton: {
    variant: 'unstyled',
    bgGradient: 'linear(to-b, alpha.300, alpha.400)',
    color: 'alpha.900',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
    fontFamily: 'body',
    fontSize: 'xs',
    fontWeight: 'bold',
    borderRadius: '4px',
    textTransform: 'uppercase',
    h: '24px',
    
    sx: {
      '&[data-active="false"]': {
        bgGradient: 'linear(to-b, alpha.300, alpha.400)',
        boxShadow:
          '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.20) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
        px: '2',
      },
  
      '&[data-active="true"]': {
        bgGradient: 'linear(to-b, alpha.800, alpha.800)',
        color: 'alpha.200',
        fontFamily: 'body',
        textShadow: '0px -1px 0px rgba(0, 0, 0, 0.25)',
        boxShadow:
          '0px 1px 2px 0px rgba(255, 255, 255, 0.40), 0px 1px 0px 0px #000 inset',
        px: '2',
      },
    },
  },
}
